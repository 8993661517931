/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { get } from 'lodash';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import './Navbar.css';
import logo from '../../assets/icons/logo.svg';
import vk from '../../assets/icons/vk.svg';
import fb from '../../assets/icons/fb.svg';
import instagram from '../../assets/icons/instagram.svg';
import tgSmall from '../../assets/icons/tgSmall.svg';
import ResultsTabs from '../ResultsTabs/ResultsTabs';
import { routes } from '../../utils/routes';
import citiesCatalogue from '../../utils/citiesCatalogue';


const cookies = new Cookies();
function Navbar({ history }) {
  const [isShowCitySelect, setIsShowCitySelect] = useState(false);
  const [selectedCity, setSelectedCity] = useState(cookies.get('city') || 'msk');

  let preLink = '';
  const cookieCity = cookies.get('city') || 'msk';
  if (cookieCity === 'msk') {
    preLink = '';
  }
  if (cookieCity === 'spb') {
    preLink = 'spb.';
  }
  if (cookieCity === 'online') {
    preLink = 'online.';
  }

  const pages = [
    { id: 'timetable', value: 'Расписание игр', link: `https://${preLink}squiz.ru/schedule` },
    { id: 'results', value: 'Рейтинг и результаты', link: null },
    { id: 'corporate', value: 'Корпоративные квизы', link: `https://${preLink}squiz.ru/event` },
    { id: 'sertificate', value: 'Сертификаты', link: `https://${preLink}squiz.ru/shop` },
    { id: 'franchise', value: 'Франшиза', link: 'https://squiz.ru/franshiza-kviza' },
    // { id: 'shop', value: 'Магазин', link: `https://${preLink}squiz.ru/shop` },
    { id: 'contact', value: 'Контакты', link: '#footer' }];
  const pathname = get(history, 'location.pathname', '').split('/')[1];
  const isNotVisible = [routes.login].includes(get(history, 'location.pathname', ''));

  const onPageClick = (link) => {
    if (link) {
      window.open(link, '_self');
    }
  };

  const onRedirectMainPage = () => {
    window.open(`https://${preLink}squiz.ru`, '_self');
  };

  const onCitySelect = (city) => {
    setSelectedCity(city);
    cookies.set('city', city, { maxAge: 60 * 60 * 24 * 365 });
    window.sessionStorage.removeItem('teamRatingFilters');
    window.sessionStorage.removeItem('gamesFilters');
    window.sessionStorage.removeItem('playerRatingFilters');
    setIsShowCitySelect(false);
    window.location.reload();
  };

  const onIconClick = (icon) => {
    switch (icon) {
      case 'tgSmall':
        window.open('https://t.me/squizmsk', '_self');
        break;
      case 'instagram':
        window.open('https://instagram.com/squiz.ru?igshid=YmMyMTA2M2Y=', '_self');
        break;
      case 'vk':
        window.open('http://vk.com/squizru', '_self');
        break;
      case 'fb':
        window.open('https://m.facebook.com/squiz.ru/', '_self');
        break;
      default: break;
    }
  };

  if (isNotVisible) {
    return null;
  }

  return (
    <>
      <div className="navbar-wrapper">
        <img className="logotype" src={logo} alt="logo" onClick={onRedirectMainPage} style={{ cursor: 'pointer' }} />
        <div className="navbar-mobi">
          <div className="navbar-pages-wrapper">
            {pages.map((page) => (
              <div
                onClick={() => onPageClick(page.link)}
                key={page.id}
                className="navbar-pages-page"
                style={page.id === pathname ? { color: '#02ACE5', cursor: 'initial' } : null}
              >
                {page.value}
              </div>
            ))}
          </div>
          <div className="navbar-city">
            <div className="navbar-city__checked" onClick={() => setIsShowCitySelect(!isShowCitySelect)}>
              <span className="navbar-city__icon" />
              <span className="navbar-city__name">{citiesCatalogue.find((c) => c.id === selectedCity)?.value}</span>
            </div>
            {isShowCitySelect && (
            <ul className="navbar-city__select">
              {selectedCity !== 'online' && <li onClick={() => onCitySelect('online')}>Онлайн</li>}
              {selectedCity !== 'spb' && <li onClick={() => onCitySelect('spb')}>Санкт-Петербург</li>}
              {selectedCity !== 'msk' && <li onClick={() => onCitySelect('msk')}>Москва</li>}
            </ul>
            )}
          </div>
          <div className="navbar-icons-wrapper">
            <img src={tgSmall} alt="logo" className="navbar-icons-icon" onClick={() => onIconClick('tgSmall')} />
            <img src={instagram} alt="logo" className="navbar-icons-icon" onClick={() => onIconClick('instagram')} />
            <img src={vk} alt="logo" className="navbar-icons-icon" onClick={() => onIconClick('vk')} />
            <img src={fb} alt="logo" className="navbar-icons-icon" onClick={() => onIconClick('fb')} />
          </div>
        </div>
        <svg
          className="icon-mobi__menu"
          width="28"
          height="20"
          viewBox="0 0 28 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="construct-menu">
            <rect className="icon-menu__line icon-menu_1" y="0" width="28" height="3" fill="white" />
            <rect className="icon-menu__line icon-menu_2" y="8" width="28" height="3" fill="white" />
            <rect className="icon-menu__line icon-menu_3" y="16" width="28" height="3" fill="white" />
          </g>
        </svg>
      </div>
      <ResultsTabs />
      {isShowCitySelect && <div className="shadow-for-navbar" onClick={() => setIsShowCitySelect(false)} />}
    </>
  );
}


Navbar.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(Navbar);
